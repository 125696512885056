/* .main-container {
  margin: 50px 50px 0 50px;
} */
h3 {
  /* font-size: 1.6rem; */
  color: #475f7b;
}
/* .span-content {
  margin: 0px 6px 0px 6px;
} */

/* .the-tournament-content {
  font-size: 1.6rem;
  font-weight: 600;
} */

.line-content {
  width: 5rem;
  height: 3px;
  /* margin: 15px 0; */
}
/* .line-content {
  width: 70px;
  height: 3px;
  margin: 15px 0;
} */

/* .image-marginRight{
  margin-right: 50px;
} */
/* Next Page */
.test {
  width: 150px;
  height:150px;
  background-color: green;
  /* margin-left: 82px; */
}
/* .margin-top{
  margin-top: 4rem;
} */
/* .theadWidth{
  width: 20px;
} */

/* .download-btn{
  width: 42.4rem;
  }
  .fontWeight{
  font-weight:300;
  } */
  
  .borderRadius{
  border-radius: 10px;
  }

/* Form Section */
  .tournament-section {
    border: 2px solid #D4D4D4;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    background-color: white;
    max-width: 450px;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tournament-section h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #2c3e50;
}

.tournament-section h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #333;
}

.tournament-section p {
    font-size: 16px;
    margin: 5px 0;
    color: #555;
}

.sponsorship-form {
    border: 2px solid #A9E79D;
    border-radius: 4px;
    display: inline-block;
    padding: 5px 10px;
    margin: 15px 0;
    font-size: 15px;
    color: #333;
}

.sponsorship-form .highlight {
    font-weight: bold;
    color: #2c3e50;
}

.details {
    font-size: 14px;
    color: #333;
}

.download-button {
    background-color: #3D63D2;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 15px;
}

.download-button:hover {
    background-color: #2E4DA8;
}

.sponsorship-container {
  text-align: center;
  /* background-color: #fff; */
  padding: 30px;
  border-radius: 20px;
  /* box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); */
  max-width: 300px;
  margin: auto;
  }
  
  .tournament-badge {
  font-size: 20px;
  font-weight: bold;
  color: #2c3e50;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .sponsorship-container h3 {
  font-size: 20px;
  color: #34495e;
  margin-bottom: 25px;
  text-transform: uppercase;
  }
  
  .cta-button {
  display: inline-block;
  background-color: #28a745;
  color: #fff;
  padding: 12px 30px;
  border-radius: 50px;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .cta-button:hover {
  background-color: #218838;
  }
  

/* Responsive Styling */
@media (max-width: 480px) {
    .tournament-section {
        max-width: 100%;
        padding: 15px;
    }

    .tournament-section h2 {
        font-size: 20px;
    }

    .tournament-section h3 {
        font-size: 16px;
    }

    .sponsorship-form {
        font-size: 12px !important;
        padding: 4px 8px ;
        margin: 6px 0;
    }

    .download-button {
        width: 100%;
        padding: 12px;
        font-size: 18px;
    }

    .iconBgDonload {
       font-size: 1rem;
    }
    .headingSize {
      font-size: 24px;
    }

    .WantSponsorshipText {
      font-size: 1rem;
    }
    


}



.borderRight{
  border-right: 1px solid #007bff;
}


/* Remove right border and add bottom border on smaller screens */
@media (max-width: 767.98px) {
  .borderBottom {
    border-right: none; /* Remove the right border */
    border-bottom: 1px solid #007bff; /* Add a bottom border */
  }
}


