.about-gbnb {
    width: 553px;
    height: 110px;
    font-family: 'Times New Roman';
    font-style: normal;
    font-weight: 700;
    font-size: 76px;
    line-height: 90px;
    display: flex;
    align-items: center;
    color: #05007E;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.content-with-css {
    font-family: 'Times New Roman';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    color: #404046;
}

.content-with-css2 {
    font-family: 'Times New Roman';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    color: #404046;
} 

.expertise-heading {
    width: 676px;
    height: 82px;
    font-family: 'Times New Roman';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
    display: flex;
    color: #212125;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.video-image {
    width: 1128px;
    height: 503px;
    border-radius: 24px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}


/* Media query for phones */
@media screen and (max-width: 767px) {
    .about-gbnb {
        width: 100%;
        height: auto;
        font-size: 36px;
        line-height: 42px;
        text-align: center;
    }

    .expertise-heading {
        width: 100%;
        height: auto;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
    }

    .content-with-css,
    .content-with-css2 {
        font-size: 14px;
        line-height: 20px;
    }

    .video-image {
        width: 100%;
        height: auto;
        border-radius: 12px;
    }

    .mainHeading{
        font-size: 22px;
    }

    .video-frame {
        height: 400px !important;
    }

    .aboutHeading {
        font-size: 24px;
        text-align: center;
    }


}