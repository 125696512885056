.upcoming-events {
  width: 332px;
  height: 51px;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 51px;
  display: flex;
  align-items: center;
  color: #05007e;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-text {
  margin-top: 10px;
  font-size: 24px;
  color: #333;
}

.event-details-page {
  width: 100%;
  max-width: auto;
  height: 610px;
  background: #f0efff;
  /* Inside auto layout */
  /* flex: none;
  order: 1;
  flex-grow: 0; */
}

@media (max-width: 576px) {
  .event-details-page {
    max-width: 100%;
  }
}

/* In app.css */

.two-days-workshop {
  height: 69px;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 69px;
  color: #ffffff;
  text-shadow: 0px 0px 36px #030130;
}

.articalHeading {
  color: #05007e;
}

.articalsContainer {
  background-color: #ffffff;
  overflow-x: none !important;
}

.articalsCardContainer p,
.netZoneCardContainer p {
  line-height: 1.1;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.articalImg img {
  height: 5.5rem;
  width: 100%;
  border-radius: 50%;
}
.articalImg {
  width: 80%;
  position: relative;
  top: 10%;
}
.articalsCardApplyBtn,
.netZoneCardApplyBtn {
  height: 25px;
  padding: 5px 12px 6px 12px;
  gap: 10px;
  border-radius: 25px 25px 25px 25px;
  opacity: 1;
  color: #05007e;
  border: 1px solid var(--primary-base, #05007e);
  background: none;
  font-size: 10px;
}
.articalsBtnApllyFilter,
.netZoneBtnApllyFilter {
  height: 32px;
  padding: 5px 12px 6px 12px;
  gap: 10px;
  border-radius: 25px 25px 25px 25px;
  opacity: 1;
  color: white;
  border: 1px solid var(--primary-base, #05007e);
  background-color: #05007e;
  font-size: 10px;
}
.articalsBtnClrFilter,
.netZoneBtnClrFilter {
  height: 32px;
  padding: 5px 12px 6px 12px;
  gap: 10px;
  border-radius: 25px 25px 25px 25px;
  opacity: 1;
  color: #05007e;
  border: 1px solid var(--primary-base, #05007e);
  background: none;
  font-size: 10px;
}

.submitVacancyDiv {
  width: 100%;
  height: 30px;
  display: flex;
  /* margin: 10px 10px; */
  /* justify-content: center; */
  float: inline-end;
  padding: 0;
}
.submitVacancyBtn,
.modalCloseBtn,
.ModalSubmitBtn{
  width: 45%;
  height: 30px;
  padding: 0;
  border-radius: 44px 44px 44px 44px;
  opacity: 1;
  color: #05007e;
  border: 1px solid var(--primary-base, #05007e);
  background: none;
  font-size: 11px;
}

.CAFirmInfoH4 {
  color: #797e00;
  font-size: 14px;
}

.paginationClass .page-link,
.page-item span {
  border-radius: 0% !important;
  background-color: none;
  color: #05007e;
  align-items: baseline !important;
  text-align: center;
}

.pagination .active > .page-link {
  background-color: #05007e !important;
  color: white !important;
  margin: 0 !important;
}
.pagination .page-link {
  color: #05007e !important;
  text-align: center !important;
}
.page-item .page-link {
  width: auto !important;
}

.custom-bg {
  background-image: url("assets/img/theme/autumn.png");
  background-size: cover;
  background-position: center;
}

/* WhatsAppIcon.css */
.whatsapp-icon {
  position: fixed;
  bottom: 10px;
  right: 5px;
  cursor: pointer;
}

.whatsapp-icon img {
  width: 50px;
  height: auto;
}

.post-title {
  background: #af9533;
  padding: 7px 20px;
  border-radius: 9px 9px 0px 0px;
  color: #fff;
  margin-bottom: 0px;
}

/* Main Backgroung colour */
.MainBackgroundColour {
  background-color: #0d0d65;
}

.socileIconRight {
  text-align: right;
}



.deepakCSS{
  padding-top: 2rem;
  padding-right: 1.5rem;
  padding-left: 3rem;
  margin-bottom: 1rem;
}


.mainTextColour {
  color: #0d0d65;
}

.chairmanPageCSS{
  padding-left: 3rem;
}

.chairmanPageCSSMsg{
  padding-left: 3.4rem;
}

.chairmanPageCSSPaeg{
  padding-left: 4rem;
}
  
.badgeRight {
  text-align: right;
}
  
.headerHidden{
  display: none;
}

.textWhiteDownloadHeader {
  color: rgba(255, 255, 255, 1);
}

.textWhiteDownloadHeader:hover {
  color: #525f7f;
}

.logosImag{
  height: 12rem;
  width: 18rem;
  cursor: pointer;
}

.singleLine {
  white-space: nowrap;
}

.wordbreakEventName {
  max-width: 24ch;
}

.inCardDateMargin {
  margin-top: -8%;
}


/* Media query for All */
@media screen and (max-width: 767px) {
  .bannerHeight {
      height: auto !important;
    }

  .divBannerHeight{
    height: auto !important;
  }

  .imgEventHeight{
    height: 400px !important;
  }

  .allMainHeading {
    font-size: 25px;
    /* text-align: center; */
  }
  .allSubHeading {
    font-size: 18px;
    /* text-align: center; */
  }

  .leftCard{
    margin-left: 18%;
    margin-top: -16%;
  }

  .mainCardHeight{
    width: 18%;
    /* height: 4.2rem; */
  }
  .colPadding{
    padding: unset;
  }

  .imageButton{
    text-align: center;
  }

  .inCardDateMargin{
    margin-top: -10%;
  }

  .chairmanHeading{
    margin-left: -25%;
  }

  .contactBackgroundColour {
    background-color: #0d0d65;
  }

  .dropdown-menu-item:hover {
    background-color: #0d0d65 !important;
  }

  .onlyCloseIcon{
    margin-top: -20%;
  }

  .deepakCSS {
    padding-top: 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .chairmanPageCSS{
    padding-left: 0.6rem;
    }
  .chairmanPageCSSMsg{
    padding-left: 1rem;
    }
  .chairmanPageCSSPaeg{
    padding-left: 0.7rem;
    }

  .donloadDataPadding {
    padding: 0.5rem !important;
  }

  .donloadDataLeftAlign {
    text-align: left !important;
  }

  .iconBgDonload {
    font-size: 1.2rem;
  }

  .imageNone {
    display: none;
  }

    .badgeLeft {
    text-align: left;
  }

  #message-box {
    /* height: 400px; */
    position: fixed;
    width: 340px !important;
  }

  .publicationsMainHeading {
    font-size: 22px;
  }

    .textWhiteDownloadHeader {
      color: #525f7f;
    }
  
    .textWhiteDownloadHeader:hover {
      color: #525f7f;
    }

    .logosImag {
      height: 12rem;
      width: 19rem;
      cursor: pointer;
    }

    .galleryMargin {
      margin-left: 5%;
    }

    /* .upcomingEventCenter{
      justify-content: center;
      align-items: center;
    } */

    .row {
      flex-direction: column;
      align-items: center;
    }

  
   
}





.hoverBTn {
  background-color: black !important;
  border: 0 !important;
  box-shadow: none !important;
  height: 35px !important;
  width: 40px !important;
  position: relative !important;
  left: 40% !important;
 
}

.eventCalenderTable th, td{
font-size: 13px;
}

.eventSpan{
  font-size: 0.6rem !important;
  font-weight:bold; 
  background-color: green !important;
  color: white !important;
  cursor: pointer;
  width: 100%;
  padding: 0% !important;
}
.calenderClass{
  height: 400px !important;
}
@media only screen and (max-width: 600px) {
  .eventSpan{
    font-size: 0.37rem !important;
    font-weight:bold; 
    background-color: green !important;
    color: white !important;
    cursor: pointer;
    width: 100%;
    padding: 0% !important;
  }
  .rbc-day-bg{
    width: 100% !important;
  }
}


/* AI Bot CSS */

#message-box {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1001;
}

#chat-icon {
  position: fixed;
  bottom: 10px;
  left: 5px;
  z-index: 1000;
}

.chat-icon {
  width: 40px;
  height: 40px;
}

/* Go To Top Icon */

.go-top-button {
  position: fixed;
  bottom: 60px;
  right: 10px;
  background-color: #7485e2;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1000;
  border-radius: 25%;
}

.go-top-button:hover {
  background-color: #555;
}


.nav-icons {
  position: fixed;
  bottom: 50%;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50px;
  z-index: 9999;
}

.icon-link {
  color: #7485e2;
  text-decoration: none;
  font-size: 24px;
  margin-bottom: 10px;
}

.icon {
  transition: transform 0.3s ease;
  width: 2rem !important;
  height: 2rem !important;
}

.icon:hover {
  transform: scale(1.2);
}

/* @keyframes blink {
  50% {
    opacity: 0;
  }
} */

/* @keyframes blink {
  0% {
      opacity: 1;
      color: rgb(160, 6, 6);
    }
  
    25% {
      opacity: 0.5;
      color: rgb(59, 5, 50);
    }
  
    50% {
      opacity: 1;
      color: green;
    }
  
    75% {
      opacity: 0.5;
      color: yellow;
    }
  
    100% {
      opacity: 1;
      color: rgb(0, 213, 255);
    }
}
.para{
  font-size: 1.10rem;
}
.blinking-text {
  animation: blink 1s infinite;
} */

@keyframes blink {
  0% {
      opacity: 1;
  }
  50% {
      opacity: 0.4;
  }
  100% {
      opacity: 1;
  }
}

.para{
  font-size: 1.10rem;
}

.blinking-text {
  animation: blink 1s infinite;
}


