/* body::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
} */

section {
    position: relative;
    z-index: 3;
    padding-top: 50px;
    padding-bottom: 0px;
}

.container {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    padding-right: 20px;
}

.section-header {
    margin-bottom: 50px;
    text-align: center;
}

.section-header h2 {
    color: #0d0d65;
    font-weight: bold;
    font-size: 3em;
    margin-bottom: 20px;
}

.section-header p {
    color: #0d0d65;
}

/* .row {
    display: flex;
    flwx-wrap: wrap;
    align-items: center;
    justify-content: space-between;
} */

.contact-info {
    width: 50%;
    padding-top: 15px;
}

.contact-info-item {
    display: flex;
    margin-bottom: 30px;
}

.contact-info-icon {
    height: 70px;
    width: 70px;
    background-color: #0d0d65;
    text-align: center;
    border-radius: 50%;
}

.contact-info-icon i {
    font-size: 30px;
    line-height: 70px;
}

.contact-info-content {
    margin-left: 20px;
}

.contact-info-content h4 {
    color: #0d0d65;
    font-size: 1.4em;
    margin-bottom: 5px;
}

.contact-info-content p {
    color: #0d0d65;
    font-size: 1em;
}

.contact-form {
    background-color: #fff;
    padding: 40px;
    width: 45%;
    padding-bottom: 20px;
    padding-top: 0px;
}

.contact-form h2 {
    font-weight: bold;
    font-size: 2em;
    margin-bottom: 10px;
    color: #0d0d65;
}

.contact-form .input-box {
    position: relative;
    width: 100%;
    margin-top: 10px;
}

.contact-form .input-box input,
.contact-form .input-box textarea {
    width: 100%;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    border: none;
    border-bottom: 2px solid #0d0d65;
    outline: none;
    resize: none;
}

.contact-form .input-box span {
    position: absolute;
    left: 0;
    padding: 5px 0;
    font-size: 16px;
    margin: 10px 0;
    pointer-events: none;
    transition: 0.5s;
    color: #666;
}

.contact-form .input-box input:focus~span,
.contact-form .input-box textarea:focus~span {
    color: #e91e63;
    font-size: 12px;
    transform: translateY(-20px);
}

.contact-form .input-box input[type="submit"] {
    width: 100%;
    background: #0d0d65;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 18px;
    border: 1px solid #0d0d65;
    transition: 0.5s;
}

.contact-form .input-box input[type="submit"]:hover {
    background: #0d0d65;
    color: #fff;
}

@media (max-width: 991px) {
    section {
        padding-top: 50px;
        padding-bottom: 0px;
    }

    .row {
        flex-direction: column;
    }

    .contact-info {
        margin-bottom: 40px;
        width: 100%;

    }

    .contact-form {
        width: 100%;
    }

    .floatingSize {
        height: 1.5rem !important;
    }

    .contact-info-icon {
        height: 61px;
        width: 65px;
        background-color: #0d0d65;
        text-align: center;
        border-radius: 50%;
    }
}