.branch-grid {
  text-align: center;
  font-family: Arial, sans-serif;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

h3 {
  font-size: 20px;
  margin-bottom: 20px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 columns */
  gap: 10px;
  justify-items: center;
}

.branch-item {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 150px;
  height: 50px;
  border-radius: 5px;
  /* font-size: 16px; */
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 2px solid #7db86a; /* Add green border */
  font-size: 14px;
  font-weight: bold;
}



.branch-item .icon {
  margin-right: 8px;
}

/* .branch-item.participation {
  background-color: #ffffff; 
  color: #012f5e; 
} */

.branch-item.organiser {
  background-color: #7db86a; /* Green background for organiser */
  color: white; /* Text color for organiser */
  font-size: 14px;
  font-weight: bold;
}

.branch-item.participation {
  background-color: #033d62; /* Dark blue background */
  color: white !important; /* Force white text color */
  font-size: 14px;
  font-weight: bold;
}



.branch-item.notparticipation:hover,
.branch-item.organiser:hover {
  background-color: #033d62;
  color: white;
  font-size: 14px;
  font-weight: bold;
}


.legend {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.legend-item:last-child {
  margin-right: 0;
}

.square {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  margin-right: 8px;
}

.organiser {
  background-color: #7db86a;
}

.participation {
  background-color: #012f5e;
}

/* span {
  font-size: 14px;
  font-weight: bold;
  color: #000;
} */



.hierarchy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-node {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.icai-box {
  padding: 10px 20px;
  border: 2px solid #58a744; /* Green border */
  color: #002952; /* Dark blue text */
  font-size: 26px;
  font-weight: bold;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.children-nodes {
  display: flex;
  justify-content: space-around;
  width: 99%;
  position: relative;
}

.node {
  text-align: center;
  margin: 0 20px;
  position: relative;
  margin-top: 5.9%;
}

.circle {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #58a744; /* Green circle */
  color: white;
  line-height: 30px;
  margin-bottom: 10px;
  font-weight: bold;
  z-index: 2;
}

.node p {
  color: #002952; /* Dark blue text */
  font-size: 16px;
  margin: 0;
}

strong {
  font-weight: bold;
  color: #002952;
}

/* Arrow Styling */
.main-node::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 30px;
  background-color: #58a744; /* Green arrow */
  top: 61px;
  left: 51%;
  transform: translateX(-50%);
}

.children-nodes::before {
  content: "";
  position: absolute;
  top: -6px;
  left: 123px;
  right: 123px;
  height: 2px;
  background-color: #58a744; /* Green line */
}

.node::before {
  content: "";
  position: absolute;
  top: -78px;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 80px;
  background-color: #58a744;
}

.children-nodes .node:nth-child(3)::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 70px;
  background-color: #58a744; /* Green arrow color */
  top: 101%;
  left: 50%;
  transform: translateX(-50%);
}


/* Mobile view */
@media (max-width: 768px) {
.grid-container {
  overflow-x: auto;
  gap: 4px;
  /* scrollbar-width: auto; */
}

body{
font-size: 13px;
}

.node {
  text-align: center;
  margin: 25px 0px;
  position: relative;
}

.main-node {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.node p {
  color: #002952;
  font-size: 11px;
  margin: 0;
}

.main-node::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 33px;
  background-color: #58a744;
  top: 62px;
  left: 45.5%;
  transform: translateX(-50%);
}

.icai-box {
  padding: 11px 25px 11px 30px;
  border: 2px solid #58a744;
  color: #002952;
  font-size: 26px;
  font-weight: bold;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.children-nodes::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 32.5px;
  right: 32.5px;
  height: 2px;
  background-color: #58a744;
}

.children-nodes {
  display: flex;
  justify-content: space-around;
  width: 125%;
  position: relative;
  margin-left: -4%;
}

.node::before {
  content: "";
  position: absolute;
  top: -33px;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 35px;
  background-color: #58a744;
}

}
