/* .vision-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: all 0.3s ease-in-out; 
    cursor: pointer;
  }
  
  .vision-container:hover {
    transform: scale(1.1); 
  } */
  .vision-container {
    padding: 5px;
    border-radius: 10px;
    height: 100px;
    width: 95%;
    margin: 5px 1%;
    float: left;
    position: relative;
    transition: height 0.5s, width 0.5s; /* Add transition for height and width */
    -webkit-transition: height 0.5s, width 0.5s; /* For Safari */
    text-align: center;
    overflow: hidden;
    
  }
  
  .vision-container:hover {
    height: 200px;
    width: 30vw;
    background-color: skyblue;
  }
  