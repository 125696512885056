
.card {
  position: relative;

  &.card-plain {
    background: transparent;
    box-shadow: none;
    border: none;
  }
}

.profile-page {
    .card-profile {
        margin-top: -150px;

        .card-profile-image {
            position: relative;
            //min-height: 130px;

            img {
                max-width: 180px;
                border-radius: $border-radius;
                @extend .shadow;
                transform: translate(-50%,-30%);
                position: absolute;
                left: 50%;
                transition: $transition-base;

                &:hover {
                    transform: translate(-50%, -33%);
                }
            }
        }

        .card-profile-stats {
            padding: 1rem 0;

            > div {
                text-align: center;
                margin-right: 1rem;
                padding: .875rem;

                &:last-child {
                    margin-right: 0;
                }

                .heading {
                    font-size: 1.1rem;
                    font-weight: bold;
                    display: block;
                }
                .description {
                    font-size: .875rem;
                    color: $gray-500;
                }
            }
        }

        .card-profile-actions {
            padding: .875rem;
        }

        @include media-breakpoint-down(xs) {
            .card-profile-actions {
                margin-top: 110px;
            }
        }

        @include media-breakpoint-between(sm, md) {
            .card-profile-stats {
                margin-top: 30px;
            }
        }
    }
}

// Card with blockquotes

.card {
  .card-blockquote {
    padding: 2rem;
    position: relative;

    .svg-bg {
        display: block;
        width: 100%;
        height: 95px;
        position: absolute;
        top: -94px;
        left: 0;
    }
  }
}

// Animated cards

.card-lift--hover {
    &:hover {
        transform: translateY(-20px);
        @include transition($transition-base);
    }
}
.card-zoom--hover:hover {
    transform: scale(1.05);
  }